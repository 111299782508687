import { useEffect, useState } from 'react';
import { distance, LineString, lineString, point } from '@turf/turf';
import { Feature } from 'geojson';
import { Key } from 'ts-key-enum';
import { drawLine } from '@/entities/map/config';
import { DISTANCE_KILOMETER, DISTANCE_METER } from '@/entities/map/constants';
import { selectIsPolygonEnabled, selectIsRulerEnabled, setIsRulerEnabled } from '@/entities/map/slices';
import { MaplibreMap } from '@/entities/map/types';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LatLngCoordinates } from '@/shared/types';

const {
  modes: { SIMPLE_SELECT, DRAW_LINE_STRING },
} = drawLine;

const FEATURE_ID = 'ruler';

const useDrawLine = (map: MaplibreMap | null) => {
  const isPolygonEnabled = useAppSelector(selectIsPolygonEnabled);
  const isRulerEnabled = useAppSelector(selectIsRulerEnabled);
  const [initCoords, setInitCoords] = useState<LatLngCoordinates | null>(null);
  const [distanceUnit, setDistanceUnit] = useState('');
  const [distanceValue, setDistanceValue] = useState('');
  const dispatch = useAppDispatch();

  const calcTotalDistance = (line: Feature<LineString>) => {
    return line.geometry.coordinates.reduce((acc, item, index, coordinates) => {
      if (index === 0) return acc;
      const prevPoint = point(coordinates[index - 1]);
      const currentPoint = point(item);
      return acc + distance(prevPoint, currentPoint, { units: 'kilometers' });
    }, 0);
  };

  const resetDistance = () => {
    setDistanceUnit('');
    setDistanceValue('');
  };

  const createRuler = (coords: LatLngCoordinates) => {
    if (isPolygonEnabled) return;
    if (isRulerEnabled) drawLine.trash();

    dispatch(setIsRulerEnabled(true));
    setInitCoords(coords);
  };

  const destroyRuler = () => {
    dispatch(setIsRulerEnabled(false));
    resetDistance();
  };

  useEffect(() => {
    resetDistance();
    if (!map?.getStyle() || isPolygonEnabled) return setInitCoords(null);

    const handleRender = () => {
      if (!isRulerEnabled || !initCoords) return;

      if (drawLine.getMode() === SIMPLE_SELECT) {
        const startPoint = [initCoords.lng, initCoords.lat];
        const feature = lineString([startPoint, startPoint], {}, { id: FEATURE_ID });

        drawLine.add(feature);
        drawLine.changeMode(DRAW_LINE_STRING, {
          featureId: FEATURE_ID,
          from: point(startPoint),
        });
      } else {
        const { features } = drawLine.getAll<LineString>();
        const line = features[0];

        const result = calcTotalDistance(line);
        const unit = result >= 1 ? DISTANCE_KILOMETER : DISTANCE_METER;
        const value = result >= 1 ? result.toFixed(1) : (result * 1000).toFixed();

        setDistanceUnit(unit);
        setDistanceValue(value);
      }
    };

    const handleModeChange = () => {
      destroyRuler();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === Key.Escape) destroyRuler();
    };

    map.on('draw.render', handleRender);
    map.on('draw.modechange', handleModeChange);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      map.off('draw.render', handleRender);
      map.off('draw.modechange', handleModeChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [map, isPolygonEnabled, isRulerEnabled, initCoords]);

  return { distanceUnit, distanceValue, createRuler };
};

export default useDrawLine;

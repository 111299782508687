import { useEffect } from 'react';
import { BACKEND_HOST } from '@/shared/config';
import { usePersistedAuthData } from '@/shared/hooks';

// media files that we retrieve from server have to have auth cookie
// useAuthCookieManager adds auth cookie only to requests to our server.

const useAuthCookieManager = () => {
  const { authData } = usePersistedAuthData();

  useEffect(() => {
    const accessToken = authData?.data?.accessToken;
    const domain = new URL(BACKEND_HOST ?? '').hostname.replace('api', '');
    if (accessToken && domain) {
      document.cookie = `X-access-token=${accessToken}; domain=${domain}; path=/; SameSite=None; Secure`;
    }
  }, [authData]);
};

export default useAuthCookieManager;

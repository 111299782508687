import { File, FileBlob, FileFilters, MarkVoiceExist, MarkVoiceSkip } from '@/entities/source/@fox/types';
import { PaginatedWithFoffset, request, RequestQueryParams, ResponseData } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import {
  downloadFileSchema,
  getFileFiltersSchema,
  getFilesSchema,
  markVoiceExistSchema,
  markVoiceSkipSchema,
} from './validationSchemas';

export const getFiles = (queryParams: RequestQueryParams) => {
  return request<PaginatedWithFoffset<File>>('/files', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFilesSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });
};

export const getFileFilters = (queryParams: RequestQueryParams) => {
  return request<ResponseData<FileFilters>>('/files/filter', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFileFiltersSchema,
    params: queryParams,
  });
};

export const downloadFile = (recordId: string) => {
  return request<FileBlob>(`/files/${recordId}/download`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: downloadFileSchema,
    responseType: 'blob',
  });
};

export const markVoiceExist = (recordId: string) => {
  return request<MarkVoiceExist>(`/files/${recordId}/has-voice`, {
    method: 'PATCH',
    endpointPrefix: V3_PREFIX,
    validationSchema: markVoiceExistSchema,
  });
};

export const markVoiceSkip = (recordId: string) => {
  return request<MarkVoiceSkip>(`/files/${recordId}/no-voice`, {
    method: 'PATCH',
    endpointPrefix: V3_PREFIX,
    validationSchema: markVoiceSkipSchema,
  });
};

export const deleteFile = (recordId: string) => {
  return request(`/files/${recordId}`, {
    method: 'DELETE',
    endpointPrefix: V3_PREFIX,
  });
};
